<template>
  <div class="player" @click.self="bgEvent">
    <!-- <video :width="videoData.playInfoList[0].width" :height="videoData.playInfoList[0].height" class="video_play" controls>
      <source :src="item.playURL" type="video/m3u8" v-for="(item, index) in videoData.playInfoList" :key="index">
      您的浏览器不支持Video标签。
    </video> -->

    <div class="video_area">
      <div id="alivideo" class="prism-player vArea" />
    </div>

    <img class="closeBtn" @click="closeEvent" src="../../assets/img/closeBtn.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'player',
  props: ['videoData', 'vid'],
  data() {
    return {
      player: null,
    }
  },
  watch: {
    videoData: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal.hasOwnProperty('playAuth')) {
          this.createPlay(newVal)
        }
      }
    }
  },
  // beforedestroy() {
  //   if (this.player !== null) {
  //     this.player.pause()
  //     this.player = null
  //   }
  // },
  methods: {
    bgEvent() {
      this.closeEvent();
    },
    closeEvent() {
      if (this.player !== null) {
        this.player.pause()
        this.player = null
      }
      let obj = {
        isShowVideo: false
      }
      this.$emit('closeEvent', obj)
    },
    createPlay(newVal) {
      this.$nextTick(()=>{
        if (this.player) this.player.dispose();
        this.player = new Aliplayer(
          {
            id: 'alivideo',
            width: '100%',
            height: '100%',
            autoplay: false,
            encryptType: 1,
            vid: this.$props.vid,
            playauth: newVal.playAuth,
          },
          function(player) {
            console.log('播放器创建好了。')
          }
        )
        console.log(this.player)
      })
    }
  }
}
</script>

<style lang="less">
  .player {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2100;
    background-color: rgba(0, 0, 0, 0.397);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .video_area {
      width: 650px;
      height: 420px;
      .vArea {
        width: 100%;
        height: 100%;
      }
    }
    .closeBtn {
      cursor: pointer;
      display: block;
      width: 40px;
      height: 40px;
      margin-top: 30px;
    }
  }
</style>